<style>
  .settings-collapse {
    margin-bottom: 1rem;
  }
  .hard-border {
    border-radius: 0 !important;
  }
</style>
<script>
import {  VclCode } from 'vue-content-loading';
import { HalfCircleSpinner } from 'epic-spinners';
import Multiselect from "vue-multiselect";

import CopyableText from "@/components/CopyableText";
import {get_acsrf_token} from "@/methods";
import {mapGetters} from "vuex";
import CopyableInput from "@/components/CopyableInput";
import {BucketType, Game} from "@/enums";

export default {
  props: ['server_id', 'options'],
  components: {

  },
  computed: {

  },
  methods: {
    async fetchMetrics() {
      try {
        let url = new URL(process.env.VUE_APP_ROOT_API + `v1/server/${this.server_id}/graphs`);
        let response = await fetch(url, {
          method: 'GET',
          credentials: 'include'
        });
        if(response.ok) {
          let data = await response.json();
          data.metrics.reverse().forEach((set) => {
            this.addServerFPS(set.serverFps);
            this.addAI(set.aiCount);
            this.addActiveAI(set.aiActive);
            this.addAnimals(set.animalCount);
            this.addVehicles(set.vehicleCount);
          });
        } else {
          throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
        }
      } catch (error) {
        console.log(`[ERROR] ${error}`);
      }
    },
    addServerFPS(value) {
      this.metrics.serverFPS.push(value);
      this.metrics.serverFPS = this.metrics.serverFPS.slice(-30);
      try {
        this.$refs.fpsChart.updateSeries([{data: this.metrics.serverFPS}]);
        // eslint-disable-next-line no-empty
      } catch(e) {}
    },
    addAI(value) {
      this.metrics.aiCount.push(value);
      this.metrics.aiCount = this.metrics.aiCount.slice(-30);
      try {
        this.$refs.aiChart.updateSeries([{data: this.metrics.aiCount}]);
        // eslint-disable-next-line no-empty
      } catch(e) {}
    },
    addActiveAI(value) {
      this.metrics.activeAI.push(value);
      this.metrics.activeAI = this.metrics.activeAI.slice(-30);
      try {
        this.$refs.activeAIChart.updateSeries([{data: this.metrics.activeAI}]);
        // eslint-disable-next-line no-empty
      } catch(e) {}
    },
    addAnimals(value) {
      this.metrics.animals.push(value);
      this.metrics.animals = this.metrics.animals.slice(-30);
      try {
        this.$refs.animalChart.updateSeries([{data: this.metrics.animals}]);
        // eslint-disable-next-line no-empty
      } catch(e) {}
    },
    addVehicles(value) {
      this.metrics.vehicles.push(value);
      this.metrics.vehicles = this.metrics.vehicles.slice(-30);
      try {
        this.$refs.vehicleChart.updateSeries([{data: this.metrics.vehicles}]);
        // eslint-disable-next-line no-empty
      } catch(e) {}
    },
	  addEntityCount(value) {
		  this.metrics.entities.push(value);
		  this.metrics.entities = this.metrics.entities.slice(-30);
		  try {
			  this.$refs.entitiesChart.updateSeries([{data: this.metrics.entities}]);
			  // eslint-disable-next-line no-empty
		  } catch(e) {}
	  },
    async handleServerMetrics(event) {
      let server_id = event.server_id || event.server.id;
      if(server_id !== this.server_id) return;
      let set = event.metrics;
      this.addServerFPS(set.serverFps);
      this.addAI(set.aiCount);
      this.addActiveAI(set.aiActive);
      this.addAnimals(set.animalCount);
      this.addVehicles(set.vehicleCount);
	    this.addEntityCount(set.entityCount);
    },
    lastElement(list) {
      if(list && list.length) {
        return list[list.length-1];
      } else return '';
    }
  },
  created() {
    this.$socket.client.on('server:metrics', this.handleServerMetrics);
    this.fetchMetrics();
    this.ready = true;
    this.$socket.client.emit('join', {type: 'metrics', server_id: this.server_id});
  },
  destroyed() {
    this.$socket.client.off('server:metrics', this.handleServerMetrics);
    this.$socket.client.emit('leave', {type: 'metrics', server_id: this.server_id});
  },
  watch: {
    '$socket.connected'() {
      if(!this.$socket.connected && this.connected) {
        this.connected = false;
      } else if(this.$socket.connected && !this.connected) {
        this.$socket.client.emit('join', {type: 'metrics', server_id: this.server_id});
      }
    }
  },
  data() {
    return {
      ready: false,
      connected: true,
      metrics: {
        serverFPS: [],
        aiCount: [],
        activeAI: [],
        animals: [],
        vehicles: [],
	      entities: []
      },
      serverFPS: {
        series: [{
          name: 'FPS',
          data: []
        }],
        chartOptions: {
          chart: {
            type: 'area',
            height: 50,
            sparkline: {
              enabled: true
            }
          },
          stroke: {
            curve: 'smooth',
            width: 2,
          },
          colors: ['#4285F4'],
          animations: {
            enabled: true,
            easing: 'linear',
            dynamicAnimation: {
              speed: 1000
            }
          },
          fill: {
            type: 'gradient',
            gradient: {
              shadeIntensity: 1,
              inverseColors: false,
              opacityFrom: 0.45,
              opacityTo: 0.05,
              stops: [25, 100, 100, 100]
            },
          },
          tooltip: {
            fixed: {
              enabled: false
            },
            x: {
              show: false
            },
            marker: {
              show: false
            }
          }
        }
      },
      ai: {
        series: [{
          name: 'AI',
          data: []
        }],
        chartOptions: {
          chart: {
            type: 'area',
            height: 50,
            sparkline: {
              enabled: true
            }
          },
          stroke: {
            curve: 'smooth',
            width: 2,
          },
          colors: ['#6f42c1'],
          animations: {
            enabled: true,
            easing: 'linear',
            dynamicAnimation: {
              speed: 1000
            }
          },
          fill: {
            type: 'gradient',
            gradient: {
              shadeIntensity: 1,
              inverseColors: false,
              opacityFrom: 0.45,
              opacityTo: 0.05,
              stops: [25, 100, 100, 100]
            },
          },
          tooltip: {
            fixed: {
              enabled: false
            },
            x: {
              show: false
            },
            marker: {
              show: false
            }
          }
        }
      },
      activeAI: {
        series: [{
          name: 'Active AI',
          data: []
        }],
        chartOptions: {
          chart: {
            type: 'area',
            height: 50,
            sparkline: {
              enabled: true
            }
          },
          stroke: {
            curve: 'smooth',
            width: 2,
          },
          colors: ['#E83E8A'],
          animations: {
            enabled: true,
            easing: 'linear',
            dynamicAnimation: {
              speed: 1000
            }
          },
          fill: {
            type: 'gradient',
            gradient: {
              shadeIntensity: 1,
              inverseColors: false,
              opacityFrom: 0.45,
              opacityTo: 0.05,
              stops: [25, 100, 100, 100]
            },
          },
          tooltip: {
            fixed: {
              enabled: false
            },
            x: {
              show: false
            },
            marker: {
              show: false
            }
          }
        }
      },
      animals: {
        series: [{
          name: 'Animals',
          data: []
        }],
        chartOptions: {
          chart: {
            type: 'area',
            height: 50,
            sparkline: {
              enabled: true
            }
          },
          stroke: {
            curve: 'smooth',
            width: 2,
          },
          colors: ['#f1b44c'],
          animations: {
            enabled: true,
            easing: 'linear',
            dynamicAnimation: {
              speed: 1000
            }
          },
          fill: {
            type: 'gradient',
            gradient: {
              shadeIntensity: 1,
              inverseColors: false,
              opacityFrom: 0.45,
              opacityTo: 0.05,
              stops: [25, 100, 100, 100]
            },
          },
          tooltip: {
            fixed: {
              enabled: false
            },
            x: {
              show: false
            },
            marker: {
              show: false
            }
          }
        }
      },
      vehicles: {
        series: [{
          name: 'Vehicles',
          data: []
        }],
        chartOptions: {
          chart: {
            type: 'area',
            height: 50,
            sparkline: {
              enabled: true
            }
          },
          stroke: {
            curve: 'smooth',
            width: 2,
          },
          colors: ['#e83e8c'],
          animations: {
            enabled: true,
            easing: 'linear',
            dynamicAnimation: {
              speed: 1000
            }
          },
          fill: {
            type: 'gradient',
            gradient: {
              shadeIntensity: 1,
              inverseColors: false,
              opacityFrom: 0.45,
              opacityTo: 0.05,
              stops: [25, 100, 100, 100]
            },
          },
          tooltip: {
            fixed: {
              enabled: false
            },
            x: {
              show: false
            },
            marker: {
              show: false
            }
          }
        }
      },
	    entities: {
		    series: [{
			    name: 'Entity Count',
			    data: []
		    }],
		    chartOptions: {
			    chart: {
				    type: 'area',
				    height: 50,
				    sparkline: {
					    enabled: true
				    }
			    },
			    stroke: {
				    curve: 'smooth',
				    width: 2,
			    },
			    colors: ['#f1734f'],
			    animations: {
				    enabled: true,
				    easing: 'linear',
				    dynamicAnimation: {
					    speed: 1000
				    }
			    },
			    fill: {
				    type: 'gradient',
				    gradient: {
					    shadeIntensity: 1,
					    inverseColors: false,
					    opacityFrom: 0.45,
					    opacityTo: 0.05,
					    stops: [25, 100, 100, 100]
				    },
			    },
			    tooltip: {
				    fixed: {
					    enabled: false
				    },
				    x: {
					    show: false
				    },
				    marker: {
					    show: false
				    }
			    }
		    }
	    }
    };
  }
};
</script>

<template>
  <div class="row justify-content-center" v-if="ready">
    <div class="col-lg-8 col-sm-12">
      <div class="row">
        <div class="col">
          <h4>
            Server FPS
            <span class="text-code">
              {{lastElement(this.metrics.serverFPS)}}
            </span>
          </h4>
          <apexchart
              ref="fpsChart"
              class="apex-charts"
              height="90"
              type="area"
              dir="ltr"
              :series="serverFPS.series"
              :options="serverFPS.chartOptions"
          ></apexchart>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col">
          <h4>
            AI
            <span class="text-code">
              {{lastElement(this.metrics.aiCount)}}
            </span>
          </h4>
          <apexchart
              ref="aiChart"
              class="apex-charts"
              height="90"
              type="area"
              dir="ltr"
              :series="ai.series"
              :options="ai.chartOptions"
          ></apexchart>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col">
          <h4>
            Active AI
            <span class="text-code">
              {{lastElement(this.metrics.activeAI)}}
            </span>
          </h4>
          <apexchart
              ref="activeAIChart"
              class="apex-charts"
              height="90"
              type="area"
              dir="ltr"
              :series="activeAI.series"
              :options="activeAI.chartOptions"
          ></apexchart>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col">
          <h4>
            Animals
            <span class="text-code">
              {{lastElement(this.metrics.animals)}}
            </span>
          </h4>
          <apexchart
              ref="animalChart"
              class="apex-charts"
              height="90"
              type="area"
              dir="ltr"
              :series="animals.series"
              :options="animals.chartOptions"
          ></apexchart>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col">
          <h4>
            Vehicles
            <span class="text-code">
              {{lastElement(this.metrics.vehicles)}}
            </span>
          </h4>
          <apexchart
              ref="vehicleChart"
              class="apex-charts"
              height="90"
              type="area"
              dir="ltr"
              :series="vehicles.series"
              :options="vehicles.chartOptions"
          ></apexchart>
        </div>
      </div>
	    <div class="row mt-2">
		    <div class="col">
			    <h4>
				    Entity Count
				    <span class="text-code">
              {{lastElement(this.metrics.entities)}}
            </span>
			    </h4>
			    <apexchart
					    ref="entitiesChart"
					    class="apex-charts"
					    height="90"
					    type="area"
					    dir="ltr"
					    :series="entities.series"
					    :options="entities.chartOptions"
			    ></apexchart>
		    </div>
	    </div>
    </div>
  </div>
</template>
